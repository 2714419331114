<!-- 订单列表 -->
<template>
  <div class="order-list-container">
    <van-tabs class="nav-tabs" v-model="activeTab" @change="onTabChange" color="#FF8F2B" line-height="4" sticky title-active-color="#FF8F2B">
      <van-tab v-for="(item, index) in navList" :key="index" :title="item.name" :name="item.value"></van-tab>
    </van-tabs>
    <!-- style="min-height: 100vh" -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <template v-if="orderList.length > 0">
          <div class="order-item" v-for="(item, index) in orderList" :key="index">
            <div @click="toDetail(item.id)">
              <p class="title">
                <span class="title-txt van-ellipsis">{{ item.productName }}</span>
                <!-- guarantee  surrender-->
                <span v-if="item.newStatus == 1" class="status-txt orange-color">待支付</span>
                <span v-else-if="item.newStatus == 2" class="status-txt orange-color">已支付</span>
                <span v-else-if="item.newStatus == 3" class="status-txt guarantee-color">保障中</span>
                <span v-else-if="item.newStatus == 4" class="status-txt surrender-color">已退保</span>
                <span v-else-if="item.newStatus == 5" class="status-txt surrender-color">已失效</span>
                <template v-else-if="item.newStatus == 6">
                  <span class="status-txt surrender-color">{{ item.statusName }}</span>
                </template>
                <template v-else-if="item.newStatus == 0">
                  <span v-if="item.statusName == '已撤销'" class="status-txt surrender-color">{{ item.statusName }}</span>
                  <span v-else class="status-txt orange-color">{{ item.statusName }}</span>
                </template>
              </p>
              <p class="time">投保时间：{{ item.insuredTime }}</p>
              <div class="content">
                <p class="name">
                  <span class="label">投/被保人：</span>
                  <span class="value van-ellipsis" style="width: 9em">{{ item.appntName }}/{{ item.insuredName }}</span>
                </p>
                <p class="ins-period" v-if="item.insStartTime && item.insEndTime">
                  <span class="label"> 保险期间：</span>
                  <span class="value">{{ item.insStartTime ? item.insStartTime : "--" }} 至 {{ item.insEndTime ? item.insEndTime : "--" }}</span>
                </p>
                <p class="premium">
                  <span class="label"> 保费：</span>
                  <span class="value">{{ item.premium }}元 </span>
                </p>
                <!-- 已退保 item.newStatus == 4 &&  -->
                <div v-if="item.surrender.surrenderPremium" class="underwriting-desc gray-gradient">
                  # 已退保：退保金额{{ item.surrender.surrenderPremium }}元
                </div>
                <!-- 未支付 -->
                <!-- <div v-if="item.manual.manualResult" class="underwriting-desc orange-gradient">
                  <p class="van-ellipsis"># {{ item.manual.manualResult }}</p>
                  <van-icon
                    v-if="item.manual.manualResult.length > 19"
                    name="question-o"
                    class="question"
                    @click.stop="showmanualResult(item.manual.manualResult)"
                  />
                </div> -->
                <div v-if="item.newManual.length > 0" class="underwriting-desc orange-gradient">
                  <div v-for="(sItem, index) in item.newManual" :key="index" class="uw-desc-item">
                    <p class="van-ellipsis">#{{ sItem.useTypeContent }}人工核保结论： {{ sItem.conclusionContent }}</p>
                    <van-icon
                      v-if="sItem.conclusionContent.length > 8"
                      name="question-o"
                      class="question"
                      @click.stop="showmanualResult(sItem.conclusionContent)"
                    />
                  </div>
                </div>

                <img
                  v-if="item.newStatus == 4 && item.surrender.surrenderPremium"
                  class="seal"
                  src="../../assets/image/guarantee-gray-seal.png"
                  alt=""
                />
                <img v-if="item.newStatus == 3" class="seal" src="../../assets/image/guaranteeing-seal.png" alt="" />
              </div>
            </div>
            <div class="btn-group">
              <!-- .orange-btn -->
              <van-popover v-if="item.newStatus == 3" v-model="item.showMore" placement="top-start" trigger="click">
                <div role="menu" class="van-popover__content">
                  <div @click.stop="showPreservationGuide(item, item.product.guideText, index)" role="menuitem" class="van-popover__action">
                    <div class="van-popover__action-text van-hairline--bottom">变更信息</div>
                  </div>
                  <div
                    v-if="item.visitStatus != 1 && item.product.needVisit == 1 && item.insuranceUrl"
                    @click.stop="showPreservationGuide(item, item.product.guideText, index)"
                    role="menuitem"
                    class="van-popover__action"
                  >
                    <div
                      class="van-popover__action-text van-hairline--bottom"
                      @click.stop="showVisitGuide(item, item.product.guideText, item.visitUrl)"
                    >
                      回访
                    </div>
                  </div>
                  <div @click.stop="" v-if="item.addInsUrl" role="menuitem" class="van-popover__action">
                    <div class="van-popover__action-text van-hairline--bottom" @click.stop="toUrl(item, item.addInsUrl)">加保</div>
                  </div>
                </div>
                <template #reference>
                  <span class="more">更多</span>
                </template>
              </van-popover>
              <div class="right">
                <button v-if="item.status == 13 && item.nextUrl" @click.stop="toUrl(item, item.nextUrl)" class="btn orange-btn">继续投保</button>
                <!-- 待支付 -->
                <template v-if="item.newStatus == 1">
                  <button
                    v-if="item.manual.exclusionUrl && item.manual.orderSn"
                    class="btn orange-btn"
                    @click.stop="toUrl(item, item.manual.exclusionUrl)"
                  >
                    确认并支付
                  </button>
                  <button v-else-if="item.payUrl" class="btn orange-btn" @click.stop="toUrl(item, item.payUrl)">立即支付</button>
                </template>
                <!-- 已支付 -->
                <template v-if="item.newStatus == 2">
                  <button
                    v-if="item.visitStatus != 1 && item.product.needVisit == 1"
                    class="btn"
                    @click.stop="showVisitGuide(item, item.product.guideText, item.visitUrl)"
                  >
                    回访
                  </button>
                  <button class="btn" @click.stop="showPreservationGuide(item, item.product.guideText)">变更信息</button>
                  <button v-if="item.role == 1 || item.role == 3" class="btn" @click.stop="showSurrenderGuide(item, item.product.guideText)">
                    退保
                  </button>
                </template>
                <!-- 保障中 -->
                <template v-if="item.newStatus == 3">
                  <button
                    v-if="item.visitStatus != 1 && item.product.needVisit == 1 && !item.insuranceUrl"
                    class="btn"
                    @click.stop="showVisitGuide(item, item.product.guideText, item.visitUrl)"
                  >
                    回访
                  </button>
                  <button v-if="item.role == 1 || item.role == 3" class="btn" @click.stop="showSurrenderGuide(item, item.product.guideText)">
                    退保
                  </button>
                  <button class="btn" @click.stop="showClaimGuide(item, item.product.guideText)">理赔指引</button>
                  <button v-if="item.insuranceUrl" class="btn" @click.stop="toUrl(item, item.insuranceUrl)">电子保单</button>
                </template>
                <!-- 已退保 -->
                <template v-if="item.newStatus == 4">
                  <!-- 空 -->
                </template>
                <!-- 已失效  -->
                <template v-if="item.newStatus == 5">
                  <button class="btn" @click="deleteOrder(item.id)">删除</button>
                </template>
                <!-- 已关闭  -->
                <template v-if="item.newStatus == 6">
                  <button class="btn" @click="deleteOrder(item.id)">删除</button>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <van-empty></van-empty>
        </template>
      </van-list>
    </van-pull-refresh>
    <van-popup closeable v-model="claimGuideVisible" title="理赔指引" round position="bottom" class="guide-popup">
      <div class="popup-title">理赔指引</div>
      <p class="title" v-if="claimGuide.firstStep">1.报案</p>
      <p class="desc" v-if="claimGuide.firstStep" v-html="claimGuide.firstStep"></p>
      <p class="title" v-if="claimGuide.secondStep">2.准备材料</p>
      <p class="desc" v-if="claimGuide.secondStep" v-html="claimGuide.secondStep"></p>
      <p class="title" v-if="claimGuide.thirdStep">3.保险公司核准</p>
      <p class="desc" v-if="claimGuide.thirdStep" v-html="claimGuide.thirdStep"></p>
      <p class="title" v-if="claimGuide.fourthStep">4.出具结果</p>
      <p class="desc" v-if="claimGuide.fourthStep" v-html="claimGuide.fourthStep"></p>
    </van-popup>
    <van-popup closeable v-model="visitGuideVisible" title="回访指引" round position="bottom" class="guide-popup">
      <div class="popup-title">回访指引</div>
      <div class="wrapper" v-for="(item, index) in visitGuide" :key="index">
        <p class="title" v-if="item.title">{{ item.title }}</p>
        <p class="desc" v-if="item.desc">{{ item.desc }}</p>
      </div>
    </van-popup>
    <van-popup closeable v-model="surrenderGuideVisible" title="退保指引" round position="bottom" class="guide-popup">
      <div class="popup-title">退保指引</div>
      <div class="wrapper" v-for="(item, index) in surrenderGuide" :key="index">
        <p class="title" v-if="item.title">{{ item.title }}</p>
        <p class="desc" v-if="item.desc">{{ item.desc }}</p>
      </div>
    </van-popup>
    <van-popup closeable v-model="preservationGuideVisible" title="保全指引" round position="bottom" class="guide-popup">
      <div class="popup-title">保全指引</div>
      <div class="wrapper" v-for="(item, index) in preservationGuide" :key="index">
        <p class="title" v-if="item.title">{{ item.title }}</p>
        <p class="desc" v-if="item.desc">{{ item.desc }}</p>
      </div>
    </van-popup>
    <van-overlay class="overlay-loading" :show="dataLoading" @click="dataLoading = false">
      <div class="overlay-wrapper" @click.stop>
        <van-loading size="24" />
        <p style="color: #fff; margin-left: 0.5em">加载中...</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getOrderList, getNavTab, deleteOrder } from "../../api/api"
import { getSession } from "../../util/util"
import loadCustomerService from "@/mixins/loadCustomerService"
export default {
  data() {
    return {
      claimGuideVisible: false,
      claimGuide: {
        firstStep: "",
        secondStep: "",
        thirdStep: "",
        fourthStep: "",
      },
      visitGuideVisible: false,
      visitGuide: [],
      preservationGuideVisible: false,
      preservationGuide: [],
      surrenderGuideVisible: false,
      surrenderGuide: [],
      activeTab: 0,
      refreshing: false,
      loading: false,
      error: false,
      finished: false,
      orderList: [],
      navList: [],
      page: 1,
      perPage: 10,
      showMore: false,
      dataLoading: false,
      moreBtnOptions: [{ text: "变更信息" }, { text: "回访" }],
      csInfo: [],
    }
  },
  mixins: [loadCustomerService],
  created() {
    const { status } = this.$route.query
    this.getNavTab()
    this.activeTab = status ? Number(status) : 0
    this.onLoad()
  },
  methods: {
    onTabChange() {
      this.page = 1
      this.orderList = []
      this.finished = false
      this.refreshing = false
      this.loading = true
      console.log("tabchange")
      this.onLoad()
    },
    async getNavTab() {
      const res = await getNavTab({ type: 1 })
      this.navList = res.data.list
    },
    async onLoad() {
      console.log("onLoad")
      if (this.refreshing) {
        this.orderList = []
        this.refreshing = false
      }

      this.dataLoading = true
      let res = await getOrderList({
        page: this.page,
        perPage: this.perPage,
        status: this.activeTab,
      })
      this.page++
      this.dataLoading = false
      this.loading = false
      this.orderList = this.orderList.concat(res.data.list)
      if (this.orderList.length > 0) {
        this.orderList.map((item) => {
          this.$set(item, "showMore", false)
          if (!item.product.guideText) {
            item.product.guideText = {
              claim: {
                firstStep: "",
                secondStep: "",
                thirdStep: "",
                fourthStep: "",
              },
              visit: [],
              surrender: [],
              preservation: [],
            }
          }
        })
      }
      if (this.orderList.length >= res.data.totalNum) {
        this.finished = true
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    toDetail(id) {
      this.$router.push(`/orderDetail?id=${id}&source=${this.$route.path}`)
    },
    showmanualResult(result) {
      this.$dialog
        .alert({
          message: result,
        })
        .then(() => {
          // on close
        })
    },
    serviceDialog(info, type) {
      let message = ""
      switch (type) {
        case "claim":
          message = "您好，暂不支持在线理赔，请联系客服处理。"
          break
        case "visit":
          message = "您好，暂不支持在线回访，请联系客服处理。 "
          break
        case "preservation":
          message = "您好，暂不支持在线变更信息，请联系客服处理。"
          break
        case "surrender":
          message = "您好，暂不支持在线退保，请联系客服处理。 "
          break
        case "url":
          message = "您好，该链接请咨询客服~ "
          break
      }
      this.$dialog
        .confirm({
          message,
          confirmButtonColor: "#fe8a3d",
          confirmButtonText: "立即咨询",
          cancelButtonText: "关闭",
        })
        .then(() => {
          try {
            console.log("info: ", info)
            const userInfo = getSession("policyToolsInfo") || {}
            const { id, name, mobile } = userInfo
            let adminUrl = process.env.VUE_APP_ADMIN
            // on close
            this.csInfo = [
              {
                key: "uid",
                label: "用户id",
                value: id,
              },
              {
                key: "uid",
                label: "用户名称",
                value: name,
              },
              {
                key: "mobile",
                label: "用户电话",
                value: mobile,
              },
              {
                key: "referringPage",
                label: "来源",
                value: "保单大师-我的订单-订单列表",
              },
              {
                key: "productName",
                label: "商品名称",
                value: info.productName,
              },
              {
                key: "orderSn",
                label: "订单号",
                value: info.orderSn,
              },
              {
                key: "insuranceSn",
                label: "保单号",
                value: info.insuranceSn ? info.insuranceSn : "未知",
              },
              {
                key: "applicantInsured",
                label: "投/被保人",
                value: `${info.appntName}${"/" + info.insuredName}`,
              },
              {
                key: "premium",
                label: "保费",
                value: info.premium,
              },
              {
                key: "status",
                label: "订单状态",
                value: info.statusName,
              },
              {
                key: "visitStatus",
                label: "回访状态",
                value: info.visitStatus || "未知",
              },
              {
                key: "visitType",
                label: "回访类型",
                value: info.visitType || "未知",
              },
            ]
            this.showChat()
          } catch (error) {
            console.log("error: ", error)
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    toUrl(info, url) {
      if (url) {
        window.location.href = url
      } else {
        this.serviceDialog(info, "url")
      }
    },
    async deleteOrder(orderId) {
      this.$dialog
        .confirm({
          message: "请确认是否删除？",
          confirmButtonColor: "#fe8a3d",
          confirmButtonText: "删除",
          cancelButtonText: "取消",
        })
        .then(async () => {
          this.dataLoading = true
          await deleteOrder({ orderId })
          this.dataLoading = false
          this.$toast("删除成功")
          this.onLoad()
        })
    },
    showVisitGuide(info, data, visitUrl, index) {
      if (index) {
        this.orderList[index].showMore = false
      }
      if (visitUrl) {
        window.location.href = visitUrl
      } else {
        if (data.visit.length > 0 && data.visit[0].title == "" && data.visit[0].desc == "") {
          this.serviceDialog(info, "visit")
          return
        }
        this.visitGuide = data.visit
        this.visitGuideVisible = true
      }
    },
    showClaimGuide(info, data) {
      if (!data.claim.firstStep) {
        this.serviceDialog(info, "claim")
        return
      }
      this.claimGuide = data.claim
      this.claimGuideVisible = true
    },
    showSurrenderGuide(info, data) {
      if (info.surrenderUrl) {
        window.location.href = info.surrenderUrl
      } else {
        if (data.surrender.length > 0 && data.surrender[0].title == "" && data.surrender[0].desc == "") {
          this.serviceDialog(info, "surrender")
          return
        }
        this.surrenderGuide = data.surrender
        this.surrenderGuideVisible = true
      }
    },
    showPreservationGuide(info, data, index) {
      if (index) {
        this.orderList[index].showMore = false
      }
      if (data.preservation.length > 0 && data.preservation[0].title == "" && data.preservation[0].desc == "") {
        this.serviceDialog(info, "preservation")
        return
      }
      this.preservationGuide = data.preservation
      this.preservationGuideVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
.order-list-container {
  padding-top: 92px;
  background-color: #fbfbfb;
  width: 100%;
  height: 100%;
  position: relative;

  .surrender-color {
    color: #98999a;
  }
  .guarantee-color {
    color: #5ba5fd;
  }
  .orange-color {
    color: #fe8a3d;
  }
  /deep/.nav-tabs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    .van-tabs__wrap {
      height: 92px;
    }
    .van-tab {
      height: 92px;
      padding: 29px 20px 0px;
      align-items: stretch;
      font-size: 34px;
      line-height: 48px;
      color: #333;
    }
  }
  /deep/.van-pull-refresh {
    background-color: #fbfbfb;
    padding: 0 24px;
    height: calc(100vh - 92px) !important;
    overflow: auto !important;
  }
  /deep/ .van-list {
    height: auto !important;
  }

  .order-item {
    width: 702px;
    margin-top: 30px;
    padding: 30px 24px;
    font-size: 30px;
    background-color: #fff;
    box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-txt {
        width: 374px;
        height: 48px;
        font-size: 34px;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
      }
      .status-txt {
        height: 40px;
        font-size: 28px;

        line-height: 40px;
      }
    }
    .time {
      height: 37px;
      margin-top: 4px;
      font-size: 26px;
      color: #777777;
      line-height: 37px;
    }
    .content {
      position: relative;
      overflow: hidden;
      width: 654px;
      margin-top: 20px;
      padding: 24px;
      background-color: #fafafa;
      border-radius: 20px;

      .label {
        color: #777;
      }
      .value {
        color: #333;
      }
      p {
        display: flex;
        height: 42px;
        line-height: 42px;
        &:nth-child(n + 2) {
          margin-top: 12px;
        }
      }
      .seal {
        position: absolute;
        top: -14px;
        right: -14px;
        width: 100px;
        height: 100px;
      }
      .gray-gradient {
        color: #777;
        background: linear-gradient(264deg, rgba(247, 247, 247, 0) 0%, #ededed 100%);
      }
      .orange-gradient {
        color: #fe8a3d;
        background: linear-gradient(264deg, rgba(255, 247, 242, 0) 0%, #fff3e8 100%);
      }
      .underwriting-desc {
        width: 600px;
        margin-top: 20px;
        padding: 10px 0 10px 16px;
        font-size: 28px;
        border-radius: 10px;
        .uw-desc-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        p {
          width: 520px;
        }
        .question {
          margin-right: 10px;
        }
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .more {
        color: #777777;
        height: 37px;
        font-size: 26px;
        color: #777777;
        line-height: 37px;
      }
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .btn {
        min-width: 168px;
        padding: 0 24px;
        height: 68px;
        margin-left: 16px;
        border-radius: 34px;
        border: 1px solid #cecfd0;
        text-align: center;
        line-height: 68px;
        font-size: 26px;
        color: #333333;
        background-color: #fff;
      }
      .orange-btn {
        border-color: #fe8a3d;
        color: #fe8a3d;
      }
    }
  }
  .overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    p {
      font-size: 32px;
    }
  }
}
</style>
